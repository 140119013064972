












import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class WaterIcon extends Vue {
  @Prop({
    type: Number,
    required: false,
    default: 100,
  })
  size!: number;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  dark!: boolean;
}
