var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-avatar', {
    staticClass: "elevation-5",
    attrs: {
      "color": _vm.dark ? 'primary' : 'white',
      "size": _vm.size
    }
  }, [_c('v-icon', {
    attrs: {
      "color": _vm.dark ? 'white' : 'secondary',
      "size": _vm.size * 0.7
    }
  }, [_vm._v("mdi-water")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }