var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-avatar', {
    staticClass: "elevation-5",
    attrs: {
      "size": _vm.size
    }
  }, [_c('svg', {
    attrs: {
      "width": _vm.size,
      "height": _vm.size
    }
  }, [_c('circle', {
    attrs: {
      "cx": _vm.cx,
      "cy": _vm.cy,
      "r": _vm.outerRadius,
      "stroke": "black",
      "stroke-width": _vm.outerStroke,
      "fill": _vm.dark ? 'var(--v-primary-base)' : 'white'
    }
  }), _vm._l(2 + _vm.nbPhases, function (i) {
    return _c('g', {
      key: i,
      attrs: {
        "fill": "grey",
        "transform": "rotate(".concat(i * 360 / (2 + _vm.nbPhases), " ").concat(_vm.cx, " ").concat(_vm.cy, ")")
      }
    }, [_c('circle', {
      attrs: {
        "cx": _vm.innerCx,
        "cy": _vm.innerCy,
        "r": _vm.innerRadius,
        "stroke": "white",
        "stroke-width": _vm.innerStroke,
        "fill": _vm.dark ? 'white' : 'var(--v-secondary-base)'
      }
    })]);
  })], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }