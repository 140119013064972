import { CommonService } from "@/services/commonService";

const V_STATUS_KO_WRONG_SEQ: string = "KO_WRONG_SEQ";
const V_STATUS_KO_GATEWAY_NOT_CONNECTED: string = "GATEWAY_NOT_CONNECTED";
const V_STATUS_KO_TIMEOUT: string = "TIMEOUT";
const V_STATUS_KO_UNKNOWN_ACTION: string = "KO_UNKNOWN_ACTION";
const V_STATUS_KO_UNKNOWN_COM_TYPE: string = "KO_UNKNOWN_COM_TYPE";
const V_STATUS_KO_CONNECT_TO_COM: string = "KO_CONNECT_TO_COM";
const V_STATUS_KO_READ_MODBUS: string = "KO_READ_MODBUS";
const V_STATUS_KO_WRITE_MODBUS: string = "KO_WRITE_MODBUS";
const V_STATUS_KO_UNSUPPORTED_PLC_VERSION: string =
  "KO_UNSUPPORTED_PLC_VERSION";
const V_STATUS_KO_WRONG_PLC_TYPE: string = "KO_WRONG_PLC_TYPE";
const V_STATUS_KO_INVALID_PLUG_POS: string = "KO_INVALID_PLUG_POS";
const V_STATUS_KO_DISABLED_PLUG: string = "KO_DISABLED_PLUG";
const V_STATUS_KO_IN_USE_PLUG: string = "KO_IN_USE_PLUG";
const V_STATUS_KO_PLUG_NOT_LOCKED: string = "KO_PLUG_NOT_LOCKED";
const V_STATUS_KO_PLUG_NOT_UNLOCKED: string = "KO_PLUG_NOT_UNLOCKED";
const V_STATUS_KO_PLUG_NOT_STARTED: string = "KO_PLUG_NOT_STARTED";
const V_STATUS_KO_PLUG_NOT_STOPED: string = "KO_PLUG_NOT_STOPED";
const V_STATUS_KO_NOT_FORCED: string = "KO_NOT_FORCED";
const V_STATUS_KO_WIFI_REQ_NUM: string = "KO_WIFI_REQ_NUM";
const V_STATUS_KO_WIFI_TIMEOUT: string = "KO_WIFI_TIMEOUT";

const BASE_URL = "api/mobile/plugs/";

class PlugsService extends CommonService {
  async open(id: number): Promise<void> {
    try {
      await this.restService.post(`${BASE_URL}${id}/open/`);
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any*/
    } catch (error: any) {
      const e = error.response?.data;
      this.handleError(e);
      throw e;
    }
  }

  async close(id: number): Promise<void> {
    try {
      await this.restService.post(`${BASE_URL}${id}/close/`);
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any*/
    } catch (error: any) {
      const e = error.response?.data;
      this.handleError(e);
      throw e;
    }
  }

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any*/
  handleError(e: any): void {
    if (!e || !e.status) {
      return;
    }

    if (e.status == V_STATUS_KO_WRONG_SEQ) {
      e.errorMsg = `Erreur de communication avec la passerelle `;
    } else if (e.status == V_STATUS_KO_GATEWAY_NOT_CONNECTED) {
      e.errorMsg = `La passerelle est déconnectée `;
    } else if (e.status == V_STATUS_KO_TIMEOUT) {
      e.errorMsg = `Délais de communication avec la passerelle dépassé `;
    } else if (e.status == V_STATUS_KO_UNKNOWN_ACTION) {
      e.errorMsg = `Action non reconnue par la passerelle `;
    } else if (e.status == V_STATUS_KO_UNKNOWN_COM_TYPE) {
      e.errorMsg = `Type de communication non reconnu `;
    } else if (e.status == V_STATUS_KO_CONNECT_TO_COM) {
      e.errorMsg = `La communication n'a pas pu être établie `;
    } else if (e.status == V_STATUS_KO_READ_MODBUS) {
      e.errorMsg = `Erreur de lecture `;
    } else if (e.status == V_STATUS_KO_WRITE_MODBUS) {
      e.errorMsg = `Erreur d'écriture `;
    } else if (e.status == V_STATUS_KO_UNSUPPORTED_PLC_VERSION) {
      e.errorMsg = `Version de la carte de commande non reconnue `;
    } else if (e.status == V_STATUS_KO_WRONG_PLC_TYPE) {
      e.errorMsg = `Type de carte de commande non reconnu `;
    } else if (e.status == V_STATUS_KO_INVALID_PLUG_POS) {
      e.errorMsg = `N° de prise ou de robinet non reconnu `;
    } else if (e.status == V_STATUS_KO_DISABLED_PLUG) {
      e.errorMsg = `Prise ou robinet désactivé `;
    } else if (e.status == V_STATUS_KO_IN_USE_PLUG) {
      e.errorMsg = `Prise ou robinet en cours d'utilisation `;
    } else if (e.status == V_STATUS_KO_PLUG_NOT_LOCKED) {
      e.errorMsg = `La prise ou le robinet n'a pas pu être attribué `;
    } else if (e.status == V_STATUS_KO_PLUG_NOT_UNLOCKED) {
      e.errorMsg = `La prise ou le robinet n'a pas pu être libéré `;
    } else if (e.status == V_STATUS_KO_PLUG_NOT_STARTED) {
      e.errorMsg = `La prise ou le robinet n'a pas pu être mis en marche `;
    } else if (e.status == V_STATUS_KO_PLUG_NOT_STOPED) {
      e.errorMsg = `La prise ou le robinet n'a pas pu être arrêté `;
    } else if (e.status == V_STATUS_KO_NOT_FORCED) {
      e.errorMsg = `La prise ou le robinet n'a pas pu être forcé `;
    } else if (e.status == V_STATUS_KO_WIFI_REQ_NUM) {
      e.errorMsg = `Erreur de communication avec la passerelle `;
    } else if (e.status == V_STATUS_KO_WIFI_TIMEOUT) {
      e.errorMsg = `Délais de communication avec le wifi dépassé `;
    }

    console.log("ici", e.status, e.errorMsg);
  }
}

export default new PlugsService();
