var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.box ? _c('v-container', [_c('v-row', [_c('v-col', {
    staticClass: "col-12",
    attrs: {
      "align": "center"
    }
  }, [_c('div', {
    staticClass: "d-inline pt-2 text-h4",
    class: _vm.getBoxColor(_vm.box) + '--text'
  }, [_vm._v(_vm._s(_vm.box.label))])])], 1), _vm.plugs.length == 0 ? _c('v-row', [_c('v-col', {
    staticClass: "col-12",
    attrs: {
      "align": "center"
    }
  }, [_c('div', [_vm._v(_vm._s(_vm.$t('box.cette-borne-na-pas-de-prise-ni-de-robinet-disponible')))])])], 1) : _vm._e(), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, _vm._l(_vm.plugs, function (plug) {
    return _c('v-col', {
      key: plug.num,
      staticClass: "col-12 col-sm-6 col-md-4 col-lg-3",
      attrs: {
        "align": "center"
      }
    }, [_c('Plug', {
      attrs: {
        "plug": plug
      },
      on: {
        "open": function open($event) {
          return _vm.open(plug);
        },
        "close": function close($event) {
          return _vm.close(plug);
        }
      }
    })], 1);
  }), 1)], 1) : _vm._e(), _c('v-overlay', {
    attrs: {
      "value": _vm.overlay
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "size": "64"
    }
  })], 1), _c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "color": "primary",
      "fab": "",
      "fixed": "",
      "bottom": "",
      "right": ""
    },
    on: {
      "click": function click($event) {
        return _vm.refresh();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-refresh")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }