
























































import { Component, Vue, Watch } from "vue-property-decorator";

import usersStore, { UsersStoreState } from "@/stores/usersStore";

import { EnergyBox } from "@/models/EnergyBox";
import energyBoxesService, { IEnergyBoxInfo } from "@/services/energyBoxesService";
import plugsService from "@/services/plugsService";
import enegyBoxesStore from "@/stores/energyBoxesStore";

import PlugComponent from "@/components/Plug.vue";

import { Customer } from "@/models/Customer";
import { Plug } from "@/models/Plug";
import { routeToMap } from "@/router/router";
// import AutoRefresh from "@/components/AutoRefresh.vue";

@Component({
  components: {
    // AutoRefresh,
    // ActivateDialog,
    Plug: PlugComponent,
  },
})
export default class Activate extends Vue {
  usersStoreState: UsersStoreState = usersStore.state;

  lastUpdateMs: number = 0;

  boxId: number | null = null;
  box: EnergyBox | null = null;

  overlay: boolean = false;

  @Watch("lastStop")
  lastStopChanged(): void {
    console.log("changed");
    this.refresh();
  }


  getBoxColor(box: EnergyBox): string {
    return box.getBoxColor(this.lastUpdateMs, "secondary");
  }

  get lastStop(): number {
    return this.usersStoreState.lastStop;
  }

  get plugs(): Plug[] {
    if (!this.box?.plugs) {
      return [];
    }

    return this.box.plugs.filter(
      (plug) =>
        plug.transaction || 
        plug.isFree()
        // (plug.isFree() &&
        //   (plug.isWater() || plug.kind == this.customer?.plug_elec))
    );
  }



  get customer(): Customer | null {
    return this.usersStoreState.customer;
  }

  async open(plug: Plug): Promise<void> {
    this.overlay = true;
    try {
      await plugsService.open(plug.id);

      if (plug.isElec()) {
        this.successNotify(this.$t('box.la-prise-a-ete-activee'));
      }
      if (plug.isWater()) {
        this.successNotify(this.$t('box.le-robinet-a-ete-active'));
      }
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any*/
    } catch (error: any) {
      if (plug.isElec()) {
        this.errorNotify(
          this.$t('box.la-prise-na-pas-pu-etre-activee'),
          error as Error,
          error?.errorMsg
        );
      }
      if (plug.isWater()) {
        this.errorNotify(
          this.$t('box.le-robinet-na-pas-pu-etre-active'),
          error as Error,
          error?.errorMsg
        );
      }
    }
    this.refresh();
  }

  async close(plug: Plug): Promise<void> {
    this.overlay = true;
    try {
      await plugsService.close(plug.id);
      if (plug.isElec()) {
        this.successNotify(this.$t('box.la-prise-a-ete-coupee'));
      }
      if (plug.isWater()) {
        this.successNotify(this.$t('box.le-robinet-a-ete-coupe'));
      }
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any*/
    } catch (error: any) {
      if (plug.isElec()) {
        this.errorNotify(
          this.$t('box.la-prise-na-pas-pu-etre-coupee'),
          error as Error,
          error?.errorMsg
        );
      }
      if (plug.isWater()) {
        this.errorNotify(
          this.$t('box.le-robinet-na-pas-pu-etre-coupe'),
          error as Error,
          error?.errorMsg
        );
      }
    } finally {
      this.overlay = false;
    }
    this.refresh();
  }

  async refresh(): Promise<void> {
    if (!this.boxId) {
      return;
    }

    this.overlay = true;
    try {
      const boxInfo: IEnergyBoxInfo = (
        await Promise.all([
          await energyBoxesService.get(this.boxId),
          usersStore.refresh(),
          enegyBoxesStore.refresh(),
        ])
      )[0];
      this.box = boxInfo.box;
      this.lastUpdateMs = Date.now();
      if (! boxInfo.updated){
        this.warningNotify(this.$t('box.les-donnees-de-cette-borne-ne-sont-peut-etre-pas-a-jour'));
      }
      // async call to keep menu's state up to date
    } catch (error) {
      this.errorNotify(this.$t('box.la-borne-na-pas-ete-trouvee'), error as Error);
    } finally {
      this.overlay = false;
    }
  }

  goToMap(): void {
    routeToMap();
  }

  mounted(): void {
    this.boxId = parseInt(this.$route.params.id);
    this.refresh();
  }
}
