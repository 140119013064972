









import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class ElecIcon extends Vue {
  @Prop({
    type: Number,
    required: true,
  })
  nbPhases!: number;

  @Prop({
    type: Number,
    required: false,
    default: 100,
  })
  size!: number;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  dark!: boolean;

  outerStroke: number = 0;
  innerStroke: number = 1;

  get outerRadius(): number {
    return (this.size * 1) / 2;
  }

  get innerRadius(): number {
    return (this.outerRadius * 1) / 5;
  }

  get cx(): number {
    return this.size / 2;
  }

  get cy(): number {
    return this.size / 2;
  }

  get innerCx(): number {
    return this.cx;
  }

  get innerCy(): number {
    return this.innerRadius * 2.2;
  }
}
