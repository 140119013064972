// import {formatFloat  } from "@/utilities/utilities"

import { Common } from "./Common";

import { TYPE_ELEC, TYPE_WATER } from "./Common";

export const STATUS_DELIVERING: string = "DELIVERING";
export const STATUS_CLOSING: string = "CLOSING";
export const STATUS_CLOSED: string = "CLOSED";

export interface ITransaction {
  id: number;
  begin: string;
  end: string;
  end_closing: string;
  status: string;
  prev_qty: number;
  current_qty: number;
  remaining_qty: number;
  query_qty: number;
  pos: string;
  plc_label: string;
  plug_label: string;
  plug_type: string;
}

export class Transaction extends Common implements ITransaction {
  id: number = 0;
  begin: string = "";
  end: string = "";
  end_closing: string = "";
  status: string = "";
  prev_qty: number = 0;
  current_qty: number = 0;
  remaining_qty: number = 0;
  query_qty: number = 0;
  pos: string = "";
  plc_label: string = "";
  plug_label: string = "";
  plug_type: string = "";

  getConsumption(): string {
    return this.formatQty(this.current_qty);
  }

  formatQty(qty: number): string {
    if (this.isElec()) {
      return this.formatElec(qty);
    }
    if (this.isWater()) {
      return this.formatWater(qty);
    }

    return "";
  }

  isElec(): boolean {
    return this.plug_type == TYPE_ELEC;
  }

  isWater(): boolean {
    return this.plug_type == TYPE_WATER;
  }
}
