




































































import { Plug } from "@/models/Plug";
import { Component, Vue, Prop } from "vue-property-decorator";

import ElecIcon from "@/components/ElecIcon.vue";
import WaterIcon from "@/components/WaterIcon.vue";

@Component({
  components: {
    ElecIcon,
    WaterIcon,
  },
})
export default class PlugComponent extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  plug!: Plug;

  open(): void {
    this.$emit("open", this.plug);
  }

  close(): void {
    this.$emit("close", this.plug);
  }
}
